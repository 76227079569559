import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import AreasTable from 'components/Web_User_Interface/720p_Series/Alarm/Areas/areasTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Areas",
  "path": "/Web_User_Interface/720p_Series/Alarm/Areas/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts its sensitivity to set a threshold for trigger events.",
  "image": "./WebUI_720p_SearchThumb_Alarm_Areas.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Alarm_Areas.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm Areas' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts its sensitivity to set a threshold for trigger events.' image='/images/Search/WebUI_720p_SearchThumb_Alarm_Areas.png' twitter='/images/Search/WebUI_720p_SearchThumb_Alarm_Areas.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Alarm/Bereiche/' locationFR='/fr/Web_User_Interface/720p_Series/Alarm/Areas/' crumbLabel="Alarm Areas" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "alarm-areas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-areas",
        "aria-label": "alarm areas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Areas`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts its sensitivity to set a threshold for trigger events.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/6d74e/WebUI-Alarm_Areas.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAAD5UlEQVQ4y52N20+TBxiHv//Fi+mdbizipiIyCxWKGDmVQumJcmhtK2ApYGFyKCIn2QAFWkoLrVSjiac5JskgqCQjs1hokRs3AbVoCwV2/yyw6Nx2t4tf3uc95HmFNEk6hQoFDvsQrmE3g4MO7I4hhl1unE4X/f2DOBxORkY9DAw6cAwN4x7x4hrxMur17cbjvYHH42PUM4bgGnFzfdjFtWMinF8fQ5ZTR4q8kwx5OxmqXtLV/aSp7aSq7YjVDpLVTkRKBycKekmSdnI8u4nE1B4Oxo/y5aHuHeEId303GDxwEO/+L9BJNUhUHZzU3kRc5EOkuc4JhRNRwVWS83tJkXUjzuskJaeV5OzLpOR3EZd4jT2fOdm3/wqC2+3i5s07lOXXYziVTkbiPs4UVqEw9GDU6ikubaS40oO6woPWPEbR+eu7rDH70FTdRmm+wenCfhIlXRxNsyH4xryM3f4RU3ktenkCGZLDyGU6zmaJMMsTqNXJqVGcosFcTa1BzwX7M6yeN1QNBDHbX1BxNYCh/We0dT4KzS4Em82G3T7ApYrTlEjjESUdolSaill5Er3sBJcq8zifvocRmxZjxufU2r6n0/UI++Qa9qdb9E5GaLv3Oxc9c1xwPEGYnf2V4LyfNmsB2rwETovjyU6LQ69KplqfxaA1E3fxXrpNYjSZiWiyEjFKjzJk72ag7zIdV9rpG+ihsbGOmgoTwubmNm9WXlFVIKFemUS9MZOO80qadZkY5CJqNclYVEcpV4qoUIspVmZRmn8Sk0LMOaWEjjod9nYLXRcraLFWI6yF13j520sspdlYSpJpajXQZTLxXZmMlm/LaLYYaNAV0VxdT5u1nHOWTnJLOtBU9qEq78FY04e1oZX6xiqsjU0I9+/d49bdWxzWx3NAuxeDJZf64lKa1DlUVyqp1qkxyaTYzDW0VJ1DV96KRFqDUt+OytRNXlEnQ24LD8eP0NbxDcLEownuj9/niO4rDuriKDLmY1IqMctzMOoKMZVokJ7JRy5VoVGUkquwcDxdj0zbgLysmQLtZRpb5Hi9cVxq3RFOTPD4yWP8gWc8n3/G0osQofkFQs/nWFpcJBR6QcA/x3xgnvngEnP+AMGFEIuLS/jnAjz4YZyHDx8w/tMdgqG5v4RPn86wurJK+PUbtre2iESirCwvsxnbIBaLsfxqmdh6dHf3enWV92th/tje5v27dwSDC/j9fgKBBcLhNYTp6WlmZmYIr4XZiMWIRCJEo1E2NjaIRKMfeafu7NZ3ef0fdztPP9wIs7/M7grfvn37cfgh6+vrf/O/+0/40wiTk5NMTU0RDof/I/w/+RMV/oH3vvguwQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6a56e7a40e05b45e0e5684a13b5915b/e4706/WebUI-Alarm_Areas.avif 230w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/d1af7/WebUI-Alarm_Areas.avif 460w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/7f308/WebUI-Alarm_Areas.avif 920w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/8a1c1/WebUI-Alarm_Areas.avif 1085w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6a56e7a40e05b45e0e5684a13b5915b/a0b58/WebUI-Alarm_Areas.webp 230w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/bc10c/WebUI-Alarm_Areas.webp 460w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/966d8/WebUI-Alarm_Areas.webp 920w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/9c2f0/WebUI-Alarm_Areas.webp 1085w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6a56e7a40e05b45e0e5684a13b5915b/81c8e/WebUI-Alarm_Areas.png 230w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/08a84/WebUI-Alarm_Areas.png 460w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/c0255/WebUI-Alarm_Areas.png 920w", "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/6d74e/WebUI-Alarm_Areas.png 1085w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6a56e7a40e05b45e0e5684a13b5915b/c0255/WebUI-Alarm_Areas.png",
              "alt": "Web User Interface - 720p Series - Alarm Areas",
              "title": "Web User Interface - 720p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "set-motion-detection-areas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-motion-detection-areas",
        "aria-label": "set motion detection areas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set Motion Detection Areas`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <AreasTable mdxType="AreasTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "define-areas-that-will-be-used-for-the-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#define-areas-that-will-be-used-for-the-motion-detection",
        "aria-label": "define areas that will be used for the motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Define Areas that will be used for the Motion Detection`}</h3>
    <p>{`The alarm area is located on the right side of the screen. You can set up to 4 detection areas - just activate an area and draw its shape. Once the motion detection is in an area is activated, its sensitivity can be adjusted from very sensitive (value = 0) to very sensitive (value = 100).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4ba4311117a91f98b72850805e14a7d5/eac55/WebUI_Areas_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AKKhoqysq3BzcWVkYnNyaGBaU2ZiWnh1a11cUnh8eouMip6hoKaoqpydnJCRj4qKiJGSkaWpqq6ztJuemwCjo6OxsrKLl411d4NVZJtXUluMgHOhnZJlY1leZVxxenGcoZeirKWQlIyGhW99fFtrbFVra2NbVlKElpQAq6ursrO0eoR8b2+ZTFfZWVVniYBte3lld29hoVtWhllTn7Os0efoYGNISUYcR0omPD4iLjIhGBYDeZGQANTU1OLi44SLhHp5o2ty5lhSYoB5am9wXXVtXLVaVqNiW6fAutbx81tgSDI1HDpDLzc9LCouIB8lFWtfVgDp6en39vafpKKJiZCLnK1gYGOAd26Si4BMSUJEQD17enens67O6OlVV0QyLxQ4OyMsLBonJxU1MiZ6bGQA1dXV3t7dhI2WYmVpV2RnU1hedWxlXllSHyMiChAOV2Nri5ma0+3vY2VHUEQcYVQvVEUpNjAfHRcIdo6MALm6urq5uWRtdE5SVzU5PVNVXGxgVy4sKEw/M2dLMnRdSnpmVJWKfFtXSUVbNUSCRnJfQTQuHwcMB0E7MwDFxcXEw8NlbXVKTVZUXmlZXWVeU0k2MCxvWEhxUThrTjVoSjRZNyBZSD9bWT9TZjhgTTNYTD1VSzg2JgsAwsLDw8LCbXWBUFVjcoGcUVFYSDwzOC0kakw1XT4pWz0pXjwoWDcgXks8RjAkPSkcNSkbT0AzkIQyjYgQAMjIyMvLzISFi2BbXWxnaHlyc390cYN0bX9nVnxjUX1jUnldTXNXRHddSUc+Ni4xL1BIPGhZS2VePmFdMgDHx8fExMTT0tHk4d7t6+rj4uPs6+rp6uvf3t3q6ung4OHh4N/p6Ofn5+fr6+rr6+vo6OjFxMS5ub7FxczKwjE1nRWJ9wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ba4311117a91f98b72850805e14a7d5/e4706/WebUI_Areas_01.avif 230w", "/en/static/4ba4311117a91f98b72850805e14a7d5/d1af7/WebUI_Areas_01.avif 460w", "/en/static/4ba4311117a91f98b72850805e14a7d5/abb2d/WebUI_Areas_01.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4ba4311117a91f98b72850805e14a7d5/a0b58/WebUI_Areas_01.webp 230w", "/en/static/4ba4311117a91f98b72850805e14a7d5/bc10c/WebUI_Areas_01.webp 460w", "/en/static/4ba4311117a91f98b72850805e14a7d5/1938b/WebUI_Areas_01.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ba4311117a91f98b72850805e14a7d5/81c8e/WebUI_Areas_01.png 230w", "/en/static/4ba4311117a91f98b72850805e14a7d5/08a84/WebUI_Areas_01.png 460w", "/en/static/4ba4311117a91f98b72850805e14a7d5/eac55/WebUI_Areas_01.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4ba4311117a91f98b72850805e14a7d5/eac55/WebUI_Areas_01.png",
              "alt": "Web User Interface - 720p Series - Alarm Areas",
              "title": "Web User Interface - 720p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Many small Alarm Areas distributed over the Frame`}</strong></p>
    <p>{`The smaller an area, the bigger (in percent) a change in the image will be. Small areas are a lot more sensitive and should be used over parts of the image, where you never want to miss someone walking through. But they also lead to a large number of false alerts und should be used sparsely. You will receive a lot of false alarm notifications in the example above.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1ab29d1124cc8d154eb2ad9ab760948b/eac55/WebUI_Areas_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AKKhoaqsrK9WVbQuLrc3Nq8sKrMxLbw6Na4tKbw+PsVGRc9RUNNUVc5OTchIR8VGRMlJSNNVVddaWs1OTQCjoqKusbC5YmDBPji7RjqtKynGQDvQTkmyMSyxMSy6OzfNUUzQVlLHSkXCQja+PS21Niq1NDCtKijDTEwAq6ursrW1rlZZykZGzltUry4rwz84vT0zuzUvvj06tDcz01lV53NzrjAhpCMPoyQSnR8QlhgQjAwCvkpKANTT0+Lk5LtfXNdNSuRqZK8sKL48Nrg5Lro0LMRHRL9CPddeXOp4eKwtIpkaDp0hF5sfFZUWD48SC7YwKgDp6en19/araZSUL3SYQYWiLEDCPDTIRUGlIyCgISG8Pj3VWlfmdHSoKiCZGAqcHRGWFg2TEwqbGhS9NjIA1dXV3+DdfVS0TxCbThegjiVRvzcsrSsqjhAQhggHrDE1xk1N6nh5rzAhqCEOsikYqiIUmxcPjgsEvEhIALm5ubu8uXBCoFIOkkwPkJElULkwJZYVFqcfGrMmGbkvJb0zKcpFPqssJKkkErowHLstIZgXD4UHBKEeGQDDw8PDxMJyRqNQDpRTGZ2TKlWyKyCaGRi3LSW4KR21KByzJhusHRGrJiGyKh61LBmwJhqrKCCnIhyVDgcAwsLCwsTBcUOlTQmXVxupjh5LqRsQnBMQtSIXrxsRrxsRrxsQrRgNsCEaoxUPnhILmhEKrCAWrSAamQ8OAMfHyMvMyoZhqmkymW48oaJKar1RS7pPTrlJQLlIP7hHPrZEPLRBOLZFPJ41MZIvLqM7Na5COqA4NZYwLwDHx8fFxcXT0tDi4Nvr6+jf4N/q6ejn6Onc3Nrq6ung4eHg3t3p6ejo6enp6+vq6+vn6OjDxMS9vr7Mzc1hrSbYb3v5gwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ab29d1124cc8d154eb2ad9ab760948b/e4706/WebUI_Areas_02.avif 230w", "/en/static/1ab29d1124cc8d154eb2ad9ab760948b/d1af7/WebUI_Areas_02.avif 460w", "/en/static/1ab29d1124cc8d154eb2ad9ab760948b/abb2d/WebUI_Areas_02.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1ab29d1124cc8d154eb2ad9ab760948b/a0b58/WebUI_Areas_02.webp 230w", "/en/static/1ab29d1124cc8d154eb2ad9ab760948b/bc10c/WebUI_Areas_02.webp 460w", "/en/static/1ab29d1124cc8d154eb2ad9ab760948b/1938b/WebUI_Areas_02.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ab29d1124cc8d154eb2ad9ab760948b/81c8e/WebUI_Areas_02.png 230w", "/en/static/1ab29d1124cc8d154eb2ad9ab760948b/08a84/WebUI_Areas_02.png 460w", "/en/static/1ab29d1124cc8d154eb2ad9ab760948b/eac55/WebUI_Areas_02.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1ab29d1124cc8d154eb2ad9ab760948b/eac55/WebUI_Areas_02.png",
              "alt": "Web User Interface - 720p Series - Alarm Areas",
              "title": "Web User Interface - 720p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`One big Area - small Areas only where they are critical`}</strong></p>
    <p>{`A big area is - according to the explanation above - much more resistant against false alerts. A big area over the whole image will only trigger an alert when a person comes very close to the camera. You can use a slightly higher sensitivity to compensate.`}</p>
    <p>{`To prevent that you might lose actual alarm events, add a smaller area where it is critical - e.g. a window or your front door.`}</p>
    <p>{`The smaller this area is, the higher its sensitivity. To find the optimal setting, keep an eye on your camera´s `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/System/System_Log/"
      }}>{`System Log`}</a>{`. If an area causes too many false alerts, make it bigger or decrease it´s sensitivity.`}</p>
    <h4 {...{
      "id": "potential-errors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#potential-errors",
        "aria-label": "potential errors permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Potential Errors`}</h4>
    <p>{`In case the camera's position is prone to rapid light changes, e.g. changes in sun intensity due to cloud movements, you might be confronted with a high number of false alarms. In this case please refer to Actions Menu for employing a PIR motion detector.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      